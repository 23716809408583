import * as React from 'react';
import { Headline, Paragraph } from '@bojagi/pablo/Typography';
import styled, { css } from 'styled-components';
import { breakpoint } from '@bojagi/pablo/styleHelpers';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { SimpleText } from '../sections/SimpleText';
import portraitJohannes from '../images/team/jm.jpg';
import portraitSimonM from '../images/team/sm.jpg';
import portraitSimonJ from '../images/team/sj.jpg';

const TeamList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${breakpoint(
    'sm',
    css`
      flex-direction: row;
    `
  )}
`;

const TeamMember = styled.div`
  margin: 16px;
  padding: 0;
  text-align: center;
`;

const Avatar = styled.img`
  overflow: hidden;
  border-radius: 50%;
`;

const MemberLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const MemberLink = styled.li``;

const TeamPage = () => (
  <Layout showLogo>
    <Seo title="Team" />
    <SimpleText>
      <Headline>The Bojagi Team</Headline>
      <Paragraph>
        Hi, we are Johannes, Simon and Simon, three software engineers from Berlin. We are building
        Bojagi to help developers and designers to communicate better, so you have more time
        building great products.
      </Paragraph>
      <TeamList>
        <TeamMember>
          <h2>Johannes Merz</h2>
          <Avatar width={200} height={200} src={portraitJohannes} alt="Portrait Johannes" />
          <p>Software Engineer</p>
          <MemberLinks>
            <MemberLink>
              <a href="https://github.com/JohannesMerz" rel="noopener noreferrer" target="_blank">
                GitHub
              </a>
            </MemberLink>
            <MemberLink>
              <a href="https://twitter.com/vargchen" rel="noopener noreferrer" target="_blank">
                Twitter
              </a>
            </MemberLink>
          </MemberLinks>
        </TeamMember>
        <TeamMember>
          <h2>Simon Males</h2>
          <Avatar width={200} height={200} src={portraitSimonM} alt="Portrait Simon M" />
          <p>Product Management and Development</p>
          <MemberLinks>
            <MemberLink>
              <a href="https://github.com/sime" rel="noopener noreferrer" target="_blank">
                GitHub
              </a>
            </MemberLink>
            <MemberLink>
              <a href="https://twitter.com/simonmales" rel="noopener noreferrer" target="_blank">
                Twitter
              </a>
            </MemberLink>
          </MemberLinks>
        </TeamMember>
        <TeamMember>
          <h2>Simon Jentsch</h2>
          <Avatar width={200} height={200} src={portraitSimonJ} alt="Portrait Simon J" />
          <p>Software Engineer &amp; Design</p>
          <MemberLinks>
            <MemberLink>
              <a href="https://github.com/tchock" rel="noopener noreferrer" target="_blank">
                GitHub
              </a>
            </MemberLink>
            <MemberLink>
              <a href="https://twitter.com/tchockie" rel="noopener noreferrer" target="_blank">
                Twitter
              </a>
            </MemberLink>
          </MemberLinks>
        </TeamMember>
      </TeamList>
    </SimpleText>
    <Container />
  </Layout>
);

export default TeamPage;
